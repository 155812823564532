/**
 * pages/404.js
 *
 * Custom 404 error page.
 * https://nextjs.org/docs/advanced-features/custom-error-page
 *
 */

import Error from "./_error";

export default function Custom404() {
    return <Error statusCode="404" />;
}
